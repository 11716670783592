import { ApolloError } from "@apollo/client";
import { Card, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { ReactFlowProvider } from "reactflow";
import {
  useGetConfigurationWithRoutesQuery,
  usePreviewFeatureGateQuery,
} from "../../graphql/generated";
import { AddDestinationsSection } from "../../pages/configurations/configuration/AddDestinationsSection";
import { AddSourcesSection } from "../../pages/configurations/configuration/AddSourcesSection";
import { Page } from "../../utils/graph/utils";
import { trimVersion } from "../../utils/version-helpers";
import { ConfigurationFlowV2 } from "../ConfigurationFlowV2";
import { EEProcessorDialogV2 } from "../EEProcessorDialog/EEProcessorDialog";
import { MaxValueMap } from "../GraphComponents";
import { DEFAULT_TELEMETRY_TYPE } from "../MeasurementControlBar";
import { ProcessorDialogV2 } from "../ProcessorsDialog/ProcessorDialog";
import { PipelineGraphErrorBoundary } from "./PipelineGraphErrorBoundary";
import { V2PipelineGraphProvider } from "./PipelineGraphV2Context";
import { RoutingContextProvider } from "./RoutingContext/RoutingContext";
import styles from "./pipeline-graph-v2.module.scss";

interface PipelineGraphV2Props {
  // configurationName is the versioned name of the configuration
  configurationName: string;
  selectedTelemetry: string;
  period: string;
  // if present the telemetry will be shown for the agent
  agentID?: string;

  readOnly?: boolean;
  skipMeasurements?: boolean;
}

export const PipelineGraphV2: React.FC<PipelineGraphV2Props> = ({
  readOnly,
  configurationName,
  period,
  selectedTelemetry,
  agentID,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [addSourceOpen, setAddSourceOpen] = useState(false);
  const [addDestinationOpen, setAddDestinationOpen] = useState(false);

  const [maxValues] = useState<MaxValueMap>({
    maxMetricValue: 0,
    maxLogValue: 0,
    maxTraceValue: 0,
  });

  function onError(err: ApolloError) {
    console.error(err);
    enqueueSnackbar(err.message, { variant: "error" });
  }

  const { data, refetch: refetchConfiguration } =
    useGetConfigurationWithRoutesQuery({
      variables: {
        name: configurationName,
      },
      fetchPolicy: "network-only",
      onError,
    });

  // const { data: measurementData } = useConfigurationMetricsSubscription({
  //   variables: {
  //     period,
  //     name: trimVersion(configurationName),
  //     agent: agentID,
  //   },
  //   onError,
  //   onData({ data }) {
  //     if (data.data?.configurationMetrics) {
  //       setMaxValues({
  //         maxMetricValue: data.data.configurationMetrics.maxMetricValue,
  //         maxLogValue: data.data.configurationMetrics.maxLogValue,
  //         maxTraceValue: data.data.configurationMetrics.maxTraceValue,
  //       });
  //     }
  //   },
  //   skip: skipMeasurements,
  // });

  const [previewEnabled, setPreviewEnabled] = useState(false);
  usePreviewFeatureGateQuery({
    onCompleted(data) {
      setPreviewEnabled(data.featureGate);
    },
    onError(err) {
      console.error(err);
      enqueueSnackbar("Error getting feature gate info", {
        variant: "error",
        key: "get-feature-gate-error",
      });
    },
  });

  if (data?.configuration == null) {
    return null;
  }

  return (
    <PipelineGraphErrorBoundary>
      <V2PipelineGraphProvider
        selectedTelemetryType={selectedTelemetry || DEFAULT_TELEMETRY_TYPE}
        configuration={data.configuration}
        refetchConfiguration={refetchConfiguration}
        addSourceOpen={addSourceOpen}
        setAddSourceOpen={setAddSourceOpen}
        addDestinationOpen={addDestinationOpen}
        setAddDestinationOpen={setAddDestinationOpen}
        readOnly={readOnly}
        maxValues={maxValues}
        agentID={agentID}
      >
        <RoutingContextProvider
          configuration={data.configuration}
          readOnly={!!readOnly}
        >
          <GraphContainer>
            <Card className={styles.card}>
              <ReactFlowProvider>
                <ConfigurationFlowV2
                  period={period}
                  selectedTelemetry={selectedTelemetry}
                  page={Page.Configuration}
                  loading={data?.configuration == null}
                  configurationName={trimVersion(configurationName)}
                  // measurementData={measurementData}
                />
              </ReactFlowProvider>
            </Card>
          </GraphContainer>
          {previewEnabled ? <EEProcessorDialogV2 /> : <ProcessorDialogV2 />}
          {!readOnly && data?.configuration && (
            <>
              <AddSourcesSection
                configuration={data.configuration}
                refetch={refetchConfiguration}
                setAddDialogOpen={setAddSourceOpen}
                addDialogOpen={addSourceOpen}
              />
              <AddDestinationsSection
                configuration={data.configuration}
                refetch={refetchConfiguration}
                setAddDialogOpen={setAddDestinationOpen}
                addDialogOpen={addDestinationOpen}
              />
            </>
          )}
        </RoutingContextProvider>
      </V2PipelineGraphProvider>
    </PipelineGraphErrorBoundary>
  );
};

const GraphContainer: React.FC = ({ children }) => {
  return (
    <Paper classes={{ root: styles.container }} elevation={1}>
      {children}
    </Paper>
  );
};
