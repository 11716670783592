import {
  MenuProps,
  Menu,
  ListItem,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListSubheader,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { ResourceConfiguration } from "../../graphql/generated";
import { getNextComponentPaths } from "../../utils/classes/resource-configuration";
import { PlusCircleIcon, XIcon } from "../Icons";
import { useV2PipelineGraph } from "../PipelineGraphV2/PipelineGraphV2Context";
import { AttributeName, V2Config } from "../PipelineGraphV2/types";
import styles from "./routing-node-menu.module.scss";

interface RoutingNodeMenuProps extends MenuProps {
  nodeType?: "source" | "destination" | "processor";
  index?: number;
  hasAvailableConnections: boolean;
  onConnectClick: (nodeType: string, index: number) => void;
  onRemoveComponentPath: (
    nodeType: "source" | "destination" | "processor",
    index: number,
    componentPath: string,
  ) => void;
}

export const RoutingNodeMenu: React.FC<RoutingNodeMenuProps> = ({
  nodeType,
  index,
  hasAvailableConnections,
  onConnectClick,
  onRemoveComponentPath,
  ...menuProps
}) => {
  const { configuration, selectedTelemetryType } = useV2PipelineGraph();

  if (nodeType == null || index == null) return null;

  const rc = getResourceConfig(configuration, nodeType, index);
  if (rc == null) throw new Error("Resource configuration not found");

  const components = getNextComponentPaths(rc, selectedTelemetryType);
  let { destinations } = reduceNextComponents(components);
  destinations = sortByDestinationPosition(configuration, destinations);

  return (
    <Menu {...menuProps} classes={{ paper: styles.menuPaper }}>
      <ListSubheader>Destinations</ListSubheader>
      {destinations.map((c) => (
        <ListItem
          key={c}
          secondaryAction={
            <IconButton
              onClick={() => onRemoveComponentPath(nodeType, index, c)}
            >
              <XIcon width="15px" height={"15px"} />
            </IconButton>
          }
        >
          <ListItemText>{trimComponentPrefix(c, "destinations")}</ListItemText>
        </ListItem>
      ))}
      {
        <ListItem>
          <Tooltip
            title={hasAvailableConnections ? null : "No available connections"}
          >
            <span>
              <ListItemButton
                onClick={() => onConnectClick(nodeType, index)}
                disabled={!hasAvailableConnections}
              >
                <ListItemIcon>
                  <PlusCircleIcon />
                </ListItemIcon>
                <ListItemText>Add Connection</ListItemText>
              </ListItemButton>
            </span>
          </Tooltip>
        </ListItem>
      }
    </Menu>
  );
};

function trimComponentPrefix(str: string, prefix: string): string {
  return str.replace(`${prefix}/`, "");
}

function reduceNextComponents(nextComponents: string[]): {
  destinations: string[];
  intermediates: string[];
} {
  let destinations = [];
  let intermediates = [];

  for (const component of nextComponents) {
    if (component.startsWith("destinations/")) {
      destinations.push(component);
    } else {
      intermediates.push(component);
    }
  }

  return { destinations, intermediates };
}

function sortByDestinationPosition(
  configuration: V2Config,
  componentPaths: string[],
): string[] {
  return componentPaths.sort((a, b) => {
    const aIndex = configuration?.graph?.targets?.findIndex(
      (t) => t.attributes[AttributeName.ComponentPath] === a,
    );
    if (aIndex == null) return -1;
    const bIndex = configuration?.graph?.targets?.findIndex(
      (t) => t.attributes[AttributeName.ComponentPath] === b,
    );
    if (bIndex == null) return 1;

    return aIndex - bIndex;
  });
}

function getResourceConfig(
  configuration: V2Config,
  nodeType: string,
  index: number,
): ResourceConfiguration | undefined {
  switch (nodeType) {
    case "source":
      return configuration?.spec?.sources?.[index];
    case "destination":
      return configuration?.spec?.destinations?.[index];
    default:
      throw new Error("Invalid node type");
  }
}
