import { memo, useEffect, useRef } from "react";
import { EdgeProps, getBezierPath } from "reactflow";
import {
  hasPipelineTypeFlag,
  PipelineTypeFlags,
} from "../../../types/configuration";
import { EdgeData } from "../../../utils/graph/types";
import { classes } from "../../../utils/styles";
import {
  edgeAnimationDuration,
  edgeStrokeOffsetSpan,
  skipFrames,
} from "../../GraphComponents/CustomEdge";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { AttributeName } from "../types";
import styles from "./configuration-edge-v2.module.scss";

const ConfigurationEdgeV2: React.FC<EdgeProps<EdgeData>> = ({
  id,
  data,
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
}) => {
  const { selectedTelemetryType, editProcessorsOpen, hoveredSet } =
    useV2PipelineGraph();
  const hiddenRef = useRef(false);
  const pathRef = useRef<SVGPathElement>(null);

  const active = hasPipelineTypeFlag(
    selectedTelemetryType,
    data?.attributes[AttributeName.ActiveTypeFlags] ?? PipelineTypeFlags.ALL,
  );

  const dimmed = hoveredSet.length > 0 && !hoveredSet.includes(id);

  // Keep track of the hidden ref
  useEffect(() => {
    hiddenRef.current = editProcessorsOpen;
  }, [editProcessorsOpen]);

  // Animation effect
  useEffect(() => {
    let frameCount = 0; // Counter for frame skipping

    // Fun fact:
    //    function animate(time: number) {
    // seems to cause a 10-20% CPU usage increase compared to:
    const animate = (time: number) => {
      frameCount++;

      if (frameCount > skipFrames) {
        if (!pathRef.current) return;

        const currentTime = time % edgeAnimationDuration;
        const progress = currentTime / edgeAnimationDuration;
        const currentOffset = progress * edgeStrokeOffsetSpan;
        pathRef.current.style.strokeDashoffset = `${currentOffset}`;

        frameCount = 0; // Reset the frame counter
      }

      if (active && !hiddenRef.current) {
        requestAnimationFrame(animate);
      }
    };

    if (active && !hiddenRef.current) {
      requestAnimationFrame(animate);
    }
  }, [active, editProcessorsOpen]);

  const [path] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <path
      ref={pathRef}
      id={id}
      d={path}
      className={makePathClass({ active, dimmed })}
    />
  );
};

type makePathClassArg = {
  active: boolean;
  dimmed: boolean;
};

function makePathClass({ active, dimmed }: makePathClassArg) {
  const classNames = [styles.noFill];

  active
    ? classNames.push(styles.activeRoute)
    : classNames.push(styles.inactiveRoute);

  dimmed && classNames.push(styles.dimmed);
  return classes(classNames);
}

export default memo(ConfigurationEdgeV2);
